<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>

        <el-form ref="form" :model="formData" :rules="rules" label-width="110px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item prop="title" label="课程资料标题">
                        <el-input size="small" v-model="formData.title" placeholder="课程资料标题"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item prop="course_name" label="课程名称">
                        <el-input size="small" v-model="formData.course_name" placeholder="课程节数"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item prop="category_id" label="选择会员等级" label-width="110px">
                        <el-select v-model="formData.member_ids" placeholder="请选择" multiple>
                            <el-option v-for="item in memberList" :key="item.id" :label="item.title"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="排序值" prop="sort" label-width="110px">
                        <el-input-number v-model="formData.sort" :min="0" label="排序值"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item prop="describe" label="资料描述">
                        <el-input type="textarea" rows="8" placeholder="请输入内容" v-model="formData.describe"
                                  maxlength="500" show-word-limit></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="封面图" prop="cover_image" label-width="110px">
                        <file-upload-img :url.sync="formData.cover_image"></file-upload-img>
                        <span>(290 * 240)</span>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item prop="file_name" label="下载文件名称" label-width="110px">
                        <el-input size="small" v-model="formData.file_name" placeholder="下载文件名称"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="下载文件" prop="phone">
                        <file-upload-video :videoUrl.sync="formData.files"></file-upload-video>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label=" " label-width="110px">
                        <el-button size="small" type="" @click="cancel">取消</el-button>
                        <el-button size="small" type="primary" @click="save">保存</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {addCourse, editCourse, detail, getAllMemberList} from './api'
import FileUploadImg from '@/components/uploader/uploader-img.vue'
import FileUploadVideo from "@/components/uploader/uploader-video.vue";

export default {
    name: 'AddOrEdit',
    components: {
        FileUploadImg,
        FileUploadVideo
    },
    data() {
        return {
            options: [],
            formData: {
                title: '',
                cover_image: '',
                course_count: '',
                describe: '',
                file_url: '',
                file_name: '',
                sort: 0,
                zx_count: 0,
                files: {},
                member_ids: []
            },
            memberList: [
                {
                    id: 0,
                    title: '请选择'
                }
            ],
            rules: {
                title: [{required: true, message: '课程资料必填', trigger: 'blur'}],
            }
        }
    },

    mounted() {
        if (this.$route.params.id) this.getDetail()
        this.getMemberList()
    },

    methods: {
        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.formData.file_url = this.formData.files.url || ''
                    if (typeof this.formData.files === Object) {
                        this.formData.file_url = this.formData.files.url
                    } else if (this.isJsonString(this.formData.files)) {
                        console.log(this.formData.files)
                        this.formData.file_url = JSON.parse(this.formData.files).url
                    }
                    if (this.formData.id) {
                        // 编辑
                        await editCourse(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addCourse(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getDetail() {
            this.formData = await detail(this.$route.params.id)
            if (this.formData.file_url !== null && this.formData.file_url.length > 0) {
                const fileList = {
                    uid: 1,
                    name: this.formData.file_name,
                    url: this.formData.file_url
                }
                this.formData.files = fileList;
            }
            console.log(this.formData)
        },
        async getMemberList() {
            this.memberList = await getAllMemberList({})
        },
        cancel() {
            this.$router.back(-1)
        },
        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }

    .course-editor {
      .el-form-item__content {
        width: 700px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
